import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import LazyBackgroundImageLoader from 'components/Img/LazyBackgroundImageLoader';
import iconSingleStar from 'assets/icon-single-star.svg';
import { media } from '../../theme/styled-theme';

/* -------------------------------------------------------------------------- */
/*                              styled components                             */
/* -------------------------------------------------------------------------- */
const rootStyle = css`
  position: absolute;
  top: 0;
  left: 0;
  min-width: 219px;
  height: 220px;
  background-position: 50% 50%;
  background-repeat: no-repeat;

  ${media.forPhoneOnly`
    min-width: 136px;
    height: 184px;
    padding: 1rem;
  `}
`;

const Root = styled.div`
  position: relative;
  border-radius: 4px;
  padding: 2rem 1rem;
  min-width: 219px;
  height: 220px;
  display: flex;
  overflow: hidden;
  ${media.forPhoneOnly`
    min-width: 136px;
    height: 184px;
    padding: 1rem;
  `}
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: center;
  font-weight: 700;
  color: white;
  text-shadow: 1px 1px 1px rgba(150, 150, 150, 0.8);
  width: 100%;
`;

const CityName = styled.h4`
  font-size: 1.125rem;
  margin: 0.5rem;
  z-index: 1;
  text-transform: capitalize;
`;

const Description = styled.div`
  display: flex;
  z-index: 1;
  flex-direction: column;
  margin: 0;
  font-size: 0.875rem;
  ${media.forPhoneOnly`
    font-weight: 400;
  `}
`;

// const Price = styled.span`
//   z-index: 1;
//   ${media.forPhoneOnly`
//     display: none;
//   `}
// `;

// Copy from AirBnb
const Overlay = styled.div`
  background-image: linear-gradient(
    -180deg,
    rgba(0, 0, 0, 0) 3%,
    rgba(0, 0, 0, 0.61) 100%
  );
  border-radius: 4px;
  opacity: 0.6;
  position: absolute;
  height: 60%;
  width: 100%;
  left: 0px;
  bottom: 0px;
`;

const Rating = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RatingStar = styled.img`
  width: 12px;
  height: 12px;
  margin-right: 4px;
`;

const Score = styled.span`
  color: white;
  font-size: 0.75rem;
`;

/* -------------------------------------------------------------------------- */
/*                               React Component                              */
/* -------------------------------------------------------------------------- */
const CityCard = memo(props => {
  const { city } = props;
  const {
    name,
    overallRating,
    photos
  } = city || {};

  const firstPhoto = useMemo(() => (photos && photos.length ? photos[0] : ''), [
    photos
  ]);

  return (
    <Root>
      <LazyBackgroundImageLoader
        src={firstPhoto}
        imgStyle={rootStyle}
        height={184}
      />
      <Overlay />
      <Content>
        <CityName data-testid="test-citycard-name">{name}</CityName>
        {
          overallRating
            ? (
              <Description>
                <Rating>
                  <RatingStar src={iconSingleStar} />
                  <Score>{overallRating}</Score>
                </Rating>
              </Description>
            ) : null
        }
      </Content>
    </Root>
  );
});

CityCard.propTypes = {
  city: PropTypes.objectOf(PropTypes.any),
};

CityCard.defaultProps = {
  city: {},
};

export default CityCard;
